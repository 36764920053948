import * as React from 'react'

import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import Seo from '../../../helpers/seo'
import Layout from '../../../templates/layout'
import Content from './content'
import flashblackJson from './flashblack.json'

import Banner from '../../../components/molecules/Banner'

const FlashBlack = () => {
  const { relational } = flashblackJson

  return (
    <Layout page="Flashblack">
      <Banner image="banner-flashblack.webp" />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}
export const Head = () => <Seo title="Flashblack" />

export default FlashBlack
